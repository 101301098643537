
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { Params, Preguntas } from '../models/modelsCodigoPalabras';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/store';
import useRegistroEntrenamiento from '../../../../rutasPrivadasMy/entrenamiento/repository/useRegistroEntrenamiento';

export const useJuegoCodigodePalabrasHooks = () => {
  const [preguntas, setPreguntas] = useState<Preguntas[]>([]);
  const [tipoPregunta, setTipoPregunta] = useState<string>('');
  const [tipoRespuesta, setTipoRespuesta] = useState<string>('');
  const [etapa, setEtapa] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [openDescripcion, setOpenDescripcion] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [objetoDescripcion, setObjetoDescripcion] = useState<number>(0);
  const [correctas, setCorrectas] = useState<number>(0);
  const [seleccionada, setSeleccionada] = useState<number[]>([]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { limit } = useParams<Params>();
  const opciones = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  
  const { actualizarRegistroEntrenamiento } = useRegistroEntrenamiento();
  const userId = useAppSelector((state) => state.auth.userId);
  const idEntrenamiento = useAppSelector((state) => state.entrenamiento.current_entrenamiento?.id ?? null);
  const indiceActividad = useAppSelector((state) => state.entrenamiento.current_actividad);
  const indiceSesion = useAppSelector((state) => state.entrenamiento.current_sesion);
  const esEntrenamiento = useAppSelector((state) => state.activity.id === "entrenamiento");
  const tipoPreguntaRedux = useAppSelector((state) => state.activity.tipoPregunta);
  const parametro = useAppSelector((state) => state.activity.tiene_bloqueo);
  const lenghtActividades = useAppSelector((state) => state.entrenamiento.current_actividades?.length);
  const emailUsuario = useAppSelector((state) => state.auth.email);

  return {
    preguntas, setPreguntas,
    tipoPregunta, setTipoPregunta,
    tipoRespuesta, setTipoRespuesta,
    etapa, setEtapa,
    loading, setLoading,
    openDescripcion, setOpenDescripcion,
    index, setIndex,
    objetoDescripcion, setObjetoDescripcion,
    correctas, setCorrectas,
    seleccionada, setSeleccionada,
    navigate,
    dispatch,
    limit,
    opciones,
    actualizarRegistroEntrenamiento,
    userId,
    idEntrenamiento,
    indiceActividad,
    indiceSesion,
    esEntrenamiento,
    tipoPreguntaRedux,
    parametro,
    lenghtActividades,
    emailUsuario
  };
};
