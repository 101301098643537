import React, { useEffect, useState, useRef, ReactNode } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useRegistroEntrenamiento from '../../repository/useRegistroEntrenamiento';

// estilos css
import estiloRuta from "../../../../../../theme/rutaEntrenamiento.module.css";
import style from "../../../../../../theme/SuperCerebrosDice.module.css";
import '../../../../../../theme/entrenamiento/entrenamiento.css';

import { CalcularEstadoDeSesion, EstadosSesion, useProxyEntrenamiento } from '../../utils/UtilsEntrenamiento';
import { SesionMapa } from '../../models/Entrenamiento';
import NeuronaSesion from '../components/NeuronaSesion';
import { Spinner } from 'react-bootstrap';
import { newActivity, changePrograma } from '../../../../../../redux/actividad/reducerActividad';
import { ChangeRedux_current_registro_usuario } from '../../../../../../redux/entrenamiento/reducerEntrenamiento';
import { useAppSelector, useAppDispatch } from '../../../../../../redux/store';
import NavigateComponent from '../../../../../components/Navigate';

type Params = {
  idEntrenamiento: string;
};

const SesionesPage: React.FC = () => {
  const userId = useAppSelector((state) => state.auth.userId);

  const currentEntrenamiento = useAppSelector(
    (state) => state.entrenamiento.current_entrenamiento
  );

  const currentRegistroRedux = useAppSelector(
    (state) => state.entrenamiento.current_registro_usuario
  );

  const currenSestionRedux = useAppSelector(
    (state) => state.entrenamiento.current_sesion
  );

  const { idEntrenamiento } = useParams<Params>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const cargarProxy = useProxyEntrenamiento(idEntrenamiento);
  const { crearPrimerRegistroEntrenamiento } = useRegistroEntrenamiento();

  const [loading, setLoading] = useState<boolean>(
    currentEntrenamiento
      ? currentRegistroRedux?.idEntrenamiento !== currentEntrenamiento.id
      : true
  );
  const [sesiones, setSesiones] = useState<SesionMapa[]>([]);
  const [mensaje, setMensaje] = useState<ReactNode>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const scroll = useRef<SVGSVGElement>(null);
  const [isListVisible, setListVisible] = useState(false);
  const [sesionList, setSesionList] = useState([]);
  useEffect(() => {
    console.log(sesionList);
  }, [sesionList]);

  // Consulta las sesiones del entrenamiento y las recorre generando repeticiones de a 3 elementos por sesion para
  // luego recorrer esto para que cada sesion tenga una aNeurona, una coneccion y un mensaje con el titulo
  const Consulta = () => {
    setSesionList(currentEntrenamiento.programas);
    setSesiones(currentEntrenamiento.sesiones);
    if (currentRegistroRedux === null) {
      crearPrimerRegistroEntrenamiento(
        userId,
        currentEntrenamiento?.id,
        null
      ).then((nuevo) => {
        dispatch(ChangeRedux_current_registro_usuario({ registro: nuevo }));
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  };

  const MostrarMensaje = (element: ReactNode) => {
    setIsVisible(true);
    setMensaje(element);
    setTimeout(() => {
      setIsVisible(false);
    }, 3000);
  };

  // Esta redireccion es para la pagina de configuracion del entrenamiento
  const RedirigirConfiguracionEntrenamiento = () => {
    dispatch(
      newActivity({ id: currentEntrenamiento.id, name: "entrenamiento" })
    );
    dispatch(changePrograma({ id: currentEntrenamiento.id }));
    return navigate(
      `/my/entrenamiento/${idEntrenamiento}/firstTime/${currentEntrenamiento.id}`
    );
  };

  // Esto determina que porcentaje se ha realizado y así mismo se establece el logo a mostrar
  const GetUrlLogo = (): string => {
    const MAXLOGOVAL = 30;

    const totalSesiones = sesiones.length;
    const actual = currentRegistroRedux.ordenSesion;

    let posicion = Math.round(actual * (MAXLOGOVAL / totalSesiones));
    if (currentRegistroRedux.completadoEntrenamiento) posicion = 30;

    return `/assets/componentes/rutaEntrenamiento/logo${posicion}.png`;
  };

  useEffect(() => {
    cargarProxy(true);
    console.log("🚀 ~ useEffect ~ currentEntrenamiento:", currentEntrenamiento);
  }, []);
  useEffect(() => {
    console.log("🚀 ~ useEffect ~ currentEntrenamiento:", currentEntrenamiento);
    if (currentEntrenamiento) {
      Consulta();
    }
  }, [currentEntrenamiento, currentRegistroRedux]);
  useEffect(() => {
    if (sesiones.length > 0) {
      if (scroll.current) {
        scroll.current?.scrollIntoView({ block: "center", behavior: "smooth" });
      } else {
        console.log("No encontro sesion actual");
        const reference = document.getElementById(
          `sesion${currenSestionRedux}`
        );
        reference?.scrollIntoView({ block: "center", behavior: "smooth" });
      }
    }
  }, [sesiones]);

  return (
    <div id="page" className="pageJuegos">
      <div className={estiloRuta.bannerTop}>
        <div className={estiloRuta.space}>
          <img
            src="/assets/icon/flechaBlancaBack.svg"
            onClick={() => navigate("/my/entrenamiento")}
          ></img>
          <img
            src="/assets/icon/icon_tornillo.svg"
            onClick={RedirigirConfiguracionEntrenamiento}
            style={{ height: "3.67svh", width: "3.67svh", color: "#FFFFFF" }}
          ></img>
        </div>
        {!loading && (
          <h1 className="titulo" style={{ textTransform: "uppercase" }}>
            {currentEntrenamiento.id}
          </h1>
        )}
        {/* <div
          style={{
            zIndex: "11",
            height: "50px",
          }}
        >
          {currentEntrenamiento?.programas && (
            <NavigateComponent
              ruta="/my/entrenamiento/memoria/general "
              onclick={() => null}
            >
              <button
                id="button_rosado_default"
                style={{
                  margin: "2px",
                }}
              >
                Programas
              </button>
            </NavigateComponent>
          )}

          {isListVisible && (
            <div
              style={{
                background: "white",
                borderRadius: "15px",
              }}
            >
              <ul
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {sesionList?.map((sesion, index) => (
                  <li key={index} style={{ listStyle: "none" }}>
                    <NavigateComponent ruta={sesion.ruta} onclick={() => null}>
                      {sesion.nombre}
                    </NavigateComponent>
                  </li>
                ))}
                {sesionList.length <= 0 && (
                  <li style={{ listStyle: "none" }}>
                    No hay sesiones disponibles
                  </li>
                )}
              </ul>
            </div>
          )}
        </div> */}
      </div>
      <img
        src="/assets/images/supercerebro/SuperCSeñalandoElcerebro.png"
        className={estiloRuta.ImagenSupercebro}
      ></img>
      <div id="content_default" className={estiloRuta.contentSesiones}>
        {loading ? (
          <Spinner></Spinner>
        ) : (
          <>
            <p className={estiloRuta.subtitulo}>
              {sesiones.length} días de Entrenamiento Cerebral
            </p>
            {isVisible && (
              <div
                className={style.mensajeTurno}
                style={{
                  position: "fixed",
                  textAlign: "center",
                  zIndex: 12,
                  opacity: "100%",
                  animation:
                    "fadeInAndGrow 1.5s forwards, fadeOut 1.5s forwards 3s",
                }}
              >
                {mensaje}
              </div>
            )}
            <div className={estiloRuta.fondo}>
              <div className={estiloRuta.listaSesionesNew}>
                {sesiones.map((sesion, index) =>
                  CalcularEstadoDeSesion(index + 1, currentRegistroRedux) !==
                  EstadosSesion.ACTUAL ? (
                    <NeuronaSesion
                      key={index}
                      index={index}
                      nombre={sesion.titulo}
                      MostrarMensaje={MostrarMensaje}
                    />
                  ) : (
                    <NeuronaSesion
                      ref={scroll}
                      key={index}
                      index={index}
                      nombre={sesion.titulo}
                      MostrarMensaje={MostrarMensaje}
                    />
                  )
                )}
              </div>
              <div className={estiloRuta.logo}>
                <img src={GetUrlLogo()}></img>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SesionesPage;
