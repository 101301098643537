import "../../../../../../theme/entrenamiento/entrenamiento.css";

import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useRegistroEntrenamiento from "../../repository/useRegistroEntrenamiento";
import { EncuentraMenorARegistro, useProxyEntrenamiento } from "../../utils/UtilsEntrenamiento";
import VistaDeJuego from "../views/VistaDeJuego";
import VistaDePrograma from "../views/VistaDePrograma";
import VistaDeRecurso from "../views/VistaDeRecurso";
import VistaDeVideo from "../views/VistaDeVideo";
import { changeCondicion } from "../../../../../../redux/actividad/reducerActividad";
import { ChangeRedux_current_indice_actividad } from "../../../../../../redux/entrenamiento/reducerEntrenamiento";
import { useAppSelector, useAppDispatch } from "../../../../../../redux/store";
import Loading from "../../../../../components/Loading";

type Props = {
  idEntrenamiento:string;
  id: string;
  index:string;
}

const RealizarActividadPage:React.FC = () => {
  const {idEntrenamiento, id, index} = useParams<Props>();
  const navigate = useNavigate();
  const cargarProxy = useProxyEntrenamiento(idEntrenamiento,id,index);

  const emailUser = useAppSelector((state) => state.auth.email)
  const redux_current_registro = useAppSelector((state) => state.entrenamiento?.current_registro_usuario);
  const actividadesRedux = useAppSelector((state) => state.entrenamiento?.current_actividades);
  
  const [videoUrl,setVideoUrl] = useState<string|string[]>(null);
  const [loading,setLoading] = useState<boolean>(true);
  const [showNeuronas,setShowNeuronas] = useState<boolean>(false);
  const [firstTime,setFirstTime] = useState<(0|1|2)>();

  const { actualizarRegistroEntrenamiento } = useRegistroEntrenamiento();

  const confirmacion = useAppSelector((state) => state.activity.confirmacion);
  const userId = useAppSelector((state) => state.auth.userId);
  const dispatch = useAppDispatch();

  // Esta función recoje la info de la sesion y carga url del video si se requiere y también de requerirse se reinicia la condicion
  // Tambien si es el primer ingreso se realiza el primer registro de la actividad
  const guardado = async() => {
    dispatch(ChangeRedux_current_indice_actividad(Number(index)))
    setVideoUrl(actividadesRedux[Number(index)]?.ruta)
    if(actividadesRedux[Number(index)]?.tipo === "programa" && !confirmacion){
      dispatch(changeCondicion({condicion:0}))
    }
  }
  
  useEffect(() => {
    setFirstTime(EncuentraMenorARegistro(Number(id)+1,Number(index)+1,redux_current_registro))
  }, [redux_current_registro])

  useEffect(() => {
    if(actividadesRedux) setLoading(false);
  }, [actividadesRedux])

  useEffect(() => {
    if(!loading){
      guardado();
    }
  }, [loading])
  
  
  useLayoutEffect(() => {
    cargarProxy()
  },[])

  // Valida todas la condiciones necesarias si se debe crear el registro del programa 
  const GuardarRegistoenFirebase = async() => {
    await actualizarRegistroEntrenamiento(emailUser,userId,idEntrenamiento,Number(id)+1,Number(index)+1);
  }  

  const handleBack = async() =>{
    setShowNeuronas(false)
    if(actividadesRedux.length === 1){
      setVideoUrl(null)
      navigate(`/my/entrenamiento/${idEntrenamiento}`);
    }else{
      setVideoUrl(null)
      navigate(`/my/entrenamiento/${idEntrenamiento}/${id}`);
    }
  }

  return (
    <>{
      loading ?
        <Loading isOpen={loading}></Loading>:
      <>
        <div id="page" style={{background:'white'}} className={actividadesRedux[Number(index)]?.tipo === "video" ? 'pageRealizarActividad':''}>
          {actividadesRedux[Number(index)]?.tipo === "video" &&
          <VistaDeVideo handleBack={handleBack} videoUrl={videoUrl} setLoading={setLoading} actividad={actividadesRedux[Number(index)]} GuardarRegistro={GuardarRegistoenFirebase} firstTime={firstTime}/>}
          {(actividadesRedux[Number(index)]?.tipo === "programa" || actividadesRedux[Number(index)]?.tipo == "cuestionario") && !loading && typeof(videoUrl) === 'string' &&
          <VistaDePrograma handleBack={handleBack} urlRedirect={videoUrl} setLoading={setLoading} actividad={actividadesRedux[Number(index)]} GuardarRegistro={GuardarRegistoenFirebase} firstTime={firstTime}/>}
          {actividadesRedux[Number(index)]?.tipo === "juego" && typeof(videoUrl) === 'string' &&
          <VistaDeJuego handleBack={handleBack} urlRedirect={videoUrl} setLoading={setLoading} actividad={actividadesRedux[Number(index)]} GuardarRegistro={GuardarRegistoenFirebase} firstTime={firstTime}/>}
          {actividadesRedux[Number(index)]?.tipo === "recurso" && typeof(videoUrl) === 'string' &&
          <VistaDeRecurso handleBack={handleBack} recursoUrl={videoUrl} setLoading={setLoading} actividad={actividadesRedux[Number(index)]} GuardarRegistro={GuardarRegistoenFirebase}/>}
        </div>
      </>
    }</>
  );
}

export default RealizarActividadPage;