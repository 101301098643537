import React, { Component } from 'react';
import '../../../../../../theme/Juegos.module.css'

interface VideoPlayerProps {
  videoUrl: string | string[];
  setLoading?:React.Dispatch<React.SetStateAction<boolean>>;
  setNeuronasGanadas?: React.Dispatch<React.SetStateAction<number>>
  autoplay?:boolean;
  poster?: string;
  preload?: string;
  adelantar?: boolean;
}

interface state{
  currentPosition:number;
  timeBeforeSeek: number | null;
}

class VideoPlayerIOs extends Component<VideoPlayerProps,state> {
  private videoRef: React.RefObject<HTMLVideoElement>;
  private miVideo = document.getElementById('videoReproduciendo');

  constructor(props: VideoPlayerProps) {
    super(props);
    this.state = {
      currentPosition: 0,
      timeBeforeSeek: null,
    };
    this.videoRef = React.createRef();
  }

  
  handleTimeUpdate = () => {
    if (this.videoRef.current) {
      this.setState({ currentPosition: this.videoRef.current.currentTime });
    }
  };

  handleSeeking = () => {
    if (this.videoRef.current) {
      const currentTime = this.videoRef.current.currentTime;
      console.log("🚀 ~ file: VideoPlayerIOs.tsx:40 ~ VideoPlayerIOs ~ currentTime:", currentTime)
      // Guardar el tiempo antes de adelantar
      this.setState({ timeBeforeSeek: currentTime });
    }
  };
  
  handleSeeked = () => {
    // Reiniciar el tiempo antes de adelantar después de que se complete el adelanto
    this.setState({ timeBeforeSeek: null });
  };

  playComponent(): void {
    this.videoRef.current.play();
  }
  pauseComponent(): void {
    this.videoRef.current.pause();
  }

  componentWillUnmount(): void {
    this.videoRef.current.pause();
    this.videoRef.current.src = '';
    if(this.props.setLoading){
      this.props.setLoading(true)
      setTimeout(()=>{
        this.props.setLoading(false)
      },1000)
    }
    console.log("🚀 ~ file: VideoPlayerIOs.tsx:42 ~ VideoPlayerIOs ~ componentDidUpdate ~ this.props.videoUrl:", this.props.videoUrl)
  }
  
  componentDidUpdate(prevProps: VideoPlayerProps) {
    // Verificar si la URL del video ha cambiado
    if (this.props.videoUrl !== prevProps.videoUrl) {
      // Detener la reproducción del video actual
      if (this.videoRef.current) {
        this.videoRef.current.pause();
        this.videoRef.current.src = '';
      }
      // Cambiar la fuente del video con la nueva URL
      if (this.videoRef.current) {
        this.videoRef.current.load();
        this.videoRef.current.play();
      }
    }
  }

  
  handleAdelantar = () => {
    if (this.videoRef.current && this.videoRef.current.currentTime !== 0 && !this.props.adelantar) {
      this.videoRef.current.currentTime = 0;
      console.log('No se permite adelantar el video.');
    }
  }

  capturarInfoVideo = (): number => {
    let miVideo = document.getElementById('videoReproduciendo');
    
    if (miVideo instanceof HTMLVideoElement && miVideo !== null) {
        let porcentaje = Number((miVideo.currentTime/miVideo.duration * 100).toFixed(0))
        if(Number.isNaN(porcentaje)){
            porcentaje = 0
        }
        this.props.setNeuronasGanadas(porcentaje)
        // setState({neuronas:porcentaje}) 
        return porcentaje
    } else {
        return 0
    }
}

  render() {
    return (
      <div style={{position: 'relative',margin:'0%', padding:'0%',alignItems: 'center',justifyContent: 'center',display:'flex',height:'100%'}}>
        <br></br>
        <video 
          autoPlay={this.props.autoplay == false ? false : true}
          ref={this.videoRef}
          controls
          controlsList="nodownload"
          onSeeked={this.handleAdelantar}
          id="videoReproduciendo"
          poster={this.props.poster ?? ""}
          preload={this.props.preload ?? ""}
          style={{ maxWidth: '100%', height: '100%'}} 
          className='video'
          playsInline
          >
            { 
              typeof(this.props.videoUrl) === 'string' ?
                <source src={"https://firebasestorage.googleapis.com/v0/b/supercerebros-desarrollo.appspot.com/o/entrenamientos%2Fneurobics%2Fconfiguracion_neurobics.mp4?alt=media&token=c1ec8d01-af31-4ca4-9b99-861987d37527"} type="video/mp4"/>
              :
              this.props.videoUrl?.map((url) => 
                <source src={url} type="video/mp4"/>)
            }
          Tu navegador no soporta la reproducción de video.
        </video>
      </div>
    );
  }
};

export default VideoPlayerIOs;
