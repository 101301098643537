
import '../../../../../../theme/entrenamiento/entrenamiento.css';
import style from '../../../../../../theme/entrenamiento/entrenamientoActividades.module.css';

import { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { EncuentraMenorARegistro, EstadosSesion, useProxyEntrenamiento } from '../../utils/UtilsEntrenamiento';
import ActividadBox from '../components/ActividadBox';
import { Modal } from 'react-bootstrap';
import { Actividad } from '../../../../../../modelos/entrenamiento/Actividad';
import { ChangeRedux_finishSesion } from '../../../../../../redux/entrenamiento/reducerEntrenamiento';
import { useAppSelector, useAppDispatch } from '../../../../../../redux/store';
import Loading from '../../../../../components/Loading';
import ProgressBarTraining from '../components/ProgressBarEntrenamiento';

type Props = {
    idEntrenamiento:string;
    id: string;
  }

const ActividadesPage = () => {
    const {id, idEntrenamiento:idEntrenamientoURL} = useParams<Props>();
    const cargarProxy = useProxyEntrenamiento(idEntrenamientoURL,id);
    const finishSesion = useAppSelector((state) => state.entrenamiento?.finishSesion);
    const currentRegistroUsuarios = useAppSelector((state) => state.entrenamiento?.current_registro_usuario);
    const currentSesionRedux = useAppSelector((state) => state.entrenamiento?.current_sesion);
    const currentActividadesRedux = useAppSelector((state) => state.entrenamiento?.current_actividades);
    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const dia = currentSesionRedux+1

    const [showFinish, setShowFinish] = useState<boolean>((finishSesion === Number(id)));
    const [array, setArray] = useState([]);
    const [loading, setLoading] = useState(!currentActividadesRedux);
    
    useLayoutEffect(() => {
        cargarProxy()
        setShowFinish((finishSesion === Number(id)))
    }, [])

    // Con la captura de info del url se determina si la sesion es completa, apenas se inicia o esta en progreso y asi da el acceso
    const Consulta = () => {
        let activities = currentActividadesRedux.map((element) =>{
            return {...element, completado: EncuentraMenorARegistro(Number(id)+1,element.orden,currentRegistroUsuarios)};
        })
        setArray(activities)
        console.log("🚀 ~ currentActividadesRedux.map ~ currentRegistroUsuarios:", currentRegistroUsuarios)
        console.log("🚀 ~ Consulta ~ activities:", activities)
        setLoading(false)
    }
    
    // Función de volver hacia atrás 
    const HandleGoBakc = () => {
        return navigate(`/my/entrenamiento/${idEntrenamientoURL}`);
    }

    //Esta determina la redireccion a la sesion correspondiente a la seleccionada
    const HandleClick = (element:Actividad,index:number) => {
        if(element.completado){
            return navigate(`/my/entrenamiento/${idEntrenamientoURL}/${id}/${index}`)
        }        
    }

    useEffect(() => {
        if(currentSesionRedux === parseInt(id)){
            if(currentActividadesRedux?.length === 1){
                navigate(`0`)
            }else{
                Consulta()
            }
        }
    }, [currentSesionRedux,currentRegistroUsuarios])
    
    const BundleShowFinish = () => {
        dispatch(ChangeRedux_finishSesion(null))
        HandleGoBakc()
    }

    useEffect(() => {
        setShowFinish((finishSesion === Number(id)))
    },[finishSesion])
    

  return (
    <div id='page'>
        <Loading isOpen={loading} />
        {!loading && currentSesionRedux === parseInt(id) && <div className='contenedor_actividades fondo' style={{display:'flex',flexDirection:'column'}}>
            <div className='contenedor_titulo_actividad'>
                <button className='backButtonEntrenamiento' onClick={HandleGoBakc}></button>
                <h1 className='dia' style={{fontSize:'3em',lineHeight:'105%'}}> DÍA {dia} </h1>
            </div>
            <div className='' style={{marginTop:'3svh',flex:'1',display:'flex',flexDirection:'column'}}>


                {array.length >=0 && 
                <div style={{width:'70%',alignSelf:'center',display:'flex',flexDirection:'column',alignItems:'center'}}>
                    <ProgressBarTraining actual={array.filter(item => item.completado === 1).length} total={array.length}/>
                </div>}

                <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',padding:'5% 10%'}} className={style.fuente_activity}>
                    {array.map((item,index) => {
                        return(
                            <ActividadBox onClick={() => {HandleClick(item,index)}} status={item.completado} title={!item.titulo ? `Actividad ${index+1}`:item?.titulo}></ActividadBox>
                        )})}
                    {array?.length > 1 && <button className={style.ButtonFinalizar_activity} 
                    style={EncuentraMenorARegistro(Number(id)+1,array.length,currentRegistroUsuarios) === EstadosSesion.COMPLETADO ? {background:'var(--color-purple-dark)',color:'white'}:{}} 
                    onClick={HandleGoBakc}>VOLVER</button>}
                </div>                
            </div>
            {showFinish && <Modal className={`${style.animationSliceCenter} ${style.contentModal}`} onClickOutside={BundleShowFinish} open={showFinish}>
                <div className={style.imagenSCVolando} style={{padding:'10%'}}>
                    {currentRegistroUsuarios.completadoEntrenamiento ? 'Completaste el entrenamiento ¡Felicidades!' :'Completaste la sesión ¡Sigue así!'}
                    <button id='button_rosado_default' className={style.buttonModal} onClick={BundleShowFinish}>{currentRegistroUsuarios.completadoEntrenamiento ? 'Finalizar' : 'Siguiente Sesión'}</button>
                </div>
            </Modal>}
        </div>}
    </div>
  )
}

export default ActividadesPage