import { useEffect, useRef, useState } from "react";
import Card from "./CartaParejas";

import '../../../../../../../theme/MemoriaParejas.css';
import { useJuegos } from "../../../../../../../repositorio/controlador/useJuegos";

interface Props{
    cantidad: number;
    visible: boolean;
    estado?: number;
    onLoad?: () => void;
    onWin: (intentos: number) => void;
}

const TableroParejas = (props: Props) => {

    const {cantidad, visible, onLoad, onWin} = props;

    const { getRutasImagenesParejas } = useJuegos();

    const [estado , setEstado] = useState<number>(props.estado ?? 0);
    const [intentos, setIntentos] = useState<number>(0);
    
    const [clickActivo, setClickActivo] = useState<boolean>(false);
    
    const [cartas, setCartas] = useState<string[]>([]);
    const [indiceCarta, setIndiceCarta] = useState<number>(-1);
    const [cartasResueltas, setCartasResueltas] = useState<number>(0);
    const [estadoCartas, setEstadoCartas] = useState<boolean[]>(Array(cantidad * 2).fill(false));
    
    const progresoCarga = useRef<number>(0);
    const inter = useRef<ReturnType<typeof setInterval> | null>(null);


    //-- functional

    const ConstruirCartas = async () => {
        let imagenes = await getRutasImagenesParejas(cantidad/2);

        let nuevasCartas: string[] = []
        imagenes.concat(imagenes).forEach((imagen, indice) => {
            nuevasCartas.push(imagen);
        });

        nuevasCartas.sort(() => (Math.random() - 0.5));

        setCartas(nuevasCartas);
        progresoCarga.current = 0;
    }

    const ActulizarCarga = async () => {
        progresoCarga.current = progresoCarga.current + 1;
    }

    //-- GameLogic

    const ManejarClickCarta = (indice: number) => {
        if(clickActivo && !estadoCartas[indice]){
            let nuevoEstadoCartas = [...estadoCartas];
            nuevoEstadoCartas[indice] = true;

            if(indiceCarta >= 0){
                setClickActivo(false);

                if(cartas[indiceCarta] !== cartas[indice]){
                    setIntentos(intentos + 1);

                    setTimeout(() => {
                        let nuevoEstadoCartas = [...estadoCartas];
                        let backupIndiceCarta = indiceCarta;

                        nuevoEstadoCartas[indice] = false;
                        nuevoEstadoCartas[backupIndiceCarta] = false;

                        setEstadoCartas(nuevoEstadoCartas);
                        setClickActivo(true);
                    }, 900);

                }else{
                    setClickActivo(true);
                    setCartasResueltas(cartasResueltas + 2);

                    if(cartasResueltas + 2 >= cartas.length){
                        onWin(intentos);
                    }
                }

                setIndiceCarta(-1);
            }else{
                setIndiceCarta(indice);
            }
            setEstadoCartas(nuevoEstadoCartas);
        }
    }



    //-- effects

    useEffect(() => {
        if (estado !== 3) return;

        console.log("effect construir cartas");
        ConstruirCartas();

        inter.current = setInterval(() => {
            if (cantidad === 0) {
                clearInterval(inter.current!);
            } else if (progresoCarga.current >= cantidad - 1) {
                clearInterval(inter.current!);
                console.log("cargado tablero tamaño", cantidad);
                onLoad();
                progresoCarga.current = 0;
            }
        }, 50);

        // Timeout para un manejo adicional
        const timeoutId = setTimeout(() => {
            if (inter.current) clearInterval(inter.current); // Verifica si inter.current no es null
            console.log("cargado tablero tamaño", cantidad);
            onLoad();
            progresoCarga.current = 0;
        }, 3000);

        return () => {
            if (inter.current) clearInterval(inter.current); // Limpia el intervalo al desmontar
            clearTimeout(timeoutId); // Limpia el timeout al desmontar
        };
    }, [cantidad, estado]);

    useEffect(() => setEstado(props.estado), [props.estado]);

    useEffect(() => {
        switch(estado){
            case 0:
                setEstadoCartas(Array(cantidad).fill(false));
                setCartasResueltas(0);
                setIntentos(0);
                break;
            case 1:
                setClickActivo(false);
                setEstadoCartas(Array(cantidad).fill(true));
                break;
            case 2:
                setEstadoCartas(Array(cantidad).fill(false));
                setTimeout(
                    () => setClickActivo(true),
                    800
                )
                break;
            case 3:
                setEstadoCartas(Array(cantidad).fill(false));
                break;
            default:
                break;
        }
    }, [estado]);

    return (
        <div className={`cubrir tableroContenedor${visible? "": " desaparecer"}`}>
            <div className={`memory-game grid${cartas.length}`} >
                {cartas.map((carta, indice) => {

                    return (<Card
                    key={indice}
                    imagen={carta}
                    volteada={estadoCartas[indice]}
                    onClick={() => ManejarClickCarta(indice)}
                    onLoad={ActulizarCarga}
                    index={indice%4 + 1}
                    />);
                })}
            </div>

            <div className="flexLayout bottomInfoContainer">
                INTENTOS: {intentos}
            </div>
        </div>
    );
}

export default TableroParejas;
