import { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFacturas } from "./repositorio/controlador/useFacturas";
import { ProductoReferencia, Factura, aFactura } from "./modelos/Facturas";
import { useAppSelector } from "./redux/store";
import compraEfectivo from "./vista/utils/compraEfectivo";
import style from "./theme/entrenamiento/componentes/checkout.module.css";


// Importa ePayco desde el objeto window
declare global {
    interface Window {
      ePayco: any; 
    }
}
interface Props {
    titulo: string;
    description:string;
    costo: number;
    iva?:number;
    tipoMoneda?:string;
    setLoading:() => void; 
    userId:string; 
    id_productos:ProductoReferencia[];
    nombre?:string;
    codigoTelefono?:string;
    telefono?:string;
    email?:string; 
    direccion?:string; 
    documentoIdentidad?:number; 
    rutaRedireccion:string;
    campaña:string;
    pagoDirecto?: {id:string, factura:Factura};
    sesionesPrueba?: number;
    urlCompra?: string;
}
const Checkout:React.FC<Props> = (props) => {
    const {
        crearFactura,
        consultarFacturaPendiente
    } = useFacturas();

    const navigate = useNavigate();
    const loggedIn = useAppSelector(state => state.auth.loggedIn);

    const [facturaPendiente, setFacturaPendiente] = useState<[string, Factura] | null>(null);

    const [total, setTotal] = useState(props.costo);

    const configCheckout = useMemo(() => {
        const config = {
            key: process.env.REACT_APP_EPAYCO_KEY,
            test: '',
            response:'',
            confirmation:''
        }
        if (process.env.REACT_APP_ENVIRONMENT === 'development') {    
            config.test = process.env.REACT_APP_EPAYCO_TEST_DEV;
            config.response = process.env.REACT_APP_EPAYCO_URL_RESPONSE_DEV; 
            config.confirmation = process.env.REACT_APP_EPAYCO_URL_CONFIRMATION_DEV;       
        } else if (process.env.REACT_APP_ENVIRONMENT === 'production') {   
            config.test = process.env.REACT_APP_EPAYCO_TEST;
            config.response = process.env.REACT_APP_EPAYCO_URL_RESPONSE;
            config.confirmation = process.env.REACT_APP_EPAYCO_URL_CONFIRMATION;
        }

        return config;
    }, [process.env.REACT_APP_ENVIRONMENT])
    
    
    const openCheckout = (e,idFactura:string, data:Props) => {
        e.preventDefault();
        var handler = window.ePayco.checkout.configure({
            key:configCheckout.key,
            test:configCheckout.test === 'true',    
        });

        let checkData = {
            name: data.titulo,
            description: data.description,
            invoice: idFactura,
            currency: props.tipoMoneda ?? 'cop',
            amount: data.costo,
            tax_base: data.costo.toString(),
            // tax: data.iva.toString(),
            country: "co",
            lang: "es",
            external: "true",
            confirmation:configCheckout.confirmation,
            response: `${configCheckout.response}${data.rutaRedireccion}`,
            name_billing: data.nombre,
            type_doc_billing: "cc",
            mobilephone_billing: data.telefono,
            email_billing: data.email,
            methodconfirmation: "get"
        };
        handler.open(checkData); 
        
        console.log(handler.open(checkData))
    }

    const setFactura = async (e, data: Props) => {
        try {
            if(total > 0 && !props.sesionesPrueba){

                const myFactura = aFactura(
                    data.id_productos, data.userId, "inicializada", total, "epayco", data.campaña,
                    {
                        nombre_usuario: data.nombre, telefono_usuario: data.telefono, codigo_telefono: data.codigoTelefono,
                        correo_usuario: data.email, url_compra: data.urlCompra, direccion_usuario: data.direccion, documento_identidad: data.documentoIdentidad
                    }
                );
                console.log(myFactura);

                const idFactura = await crearFactura(myFactura);
                console.log(idFactura)
                openCheckout(e,idFactura, data);
                console.log('Factura creada exitosamente');
            }else{

                await compraEfectivo(configCheckout.confirmation, 
                    {
                        nombre: data.nombre,
                        telefono: data.telefono,
                        email: data.email,
                        codigoTelefono: data.codigoTelefono,
                        campaña: data.campaña,
                        id_productos: data.id_productos,
                        userId: data.userId,
                        valorPagado: 0,
                        pruebaGratuita: props.sesionesPrueba,
                        urlCompra: data.urlCompra
                    }
                );

                if(loggedIn) navigate(`/my/entrenamiento`);
                else{
                    window.location.href = `${configCheckout.response}/login`;
                }
            }
        } catch (error) {
            console.error('Error al crear la factura:', error);
        }
    }

    const PagarPendiente = (e, factura:[string, Factura]) => {

        const data = {...props};

        data.titulo = `Entrenamiento ${factura[1]}`;
        data.description = data.titulo;
        setTotal(factura[1].costo_pagar);
        data.costo = factura[1].costo_pagar;
        data.nombre = factura[1].nombre_usuario;
        data.telefono = factura[1].telefono_usuario;
        data.codigoTelefono = factura[1].codigo_telefono;
        data.email = factura[1].correo_usuario;
        data.direccion = factura[1].direccion_usuario;

        props.setLoading();
        openCheckout(e, factura[0], data);
        props.setLoading();
    }

    const handleClick = async (e) => {
        props.setLoading();
        await setFactura(e, props);
    }

    useEffect(() => {
        props.setLoading();
        console.log(configCheckout);

        if(props.pagoDirecto){
            const {id, factura} = props.pagoDirecto;
            PagarPendiente(new Event(""), [id, factura]);
            props.setLoading()
        }
        else consultarFacturaPendiente(props.nombre, props.telefono, props.email, props.id_productos).then(res => {
            setFacturaPendiente(res);
            props.setLoading();
        });
    }, []);

    return (
        <>
            {facturaPendiente &&
                <div className={style.pendientePopOver}>
                    <p className={style.tituloPopOver}>Tiene una Factura pendiente con los siguientes datos</p>
                    <br/>
                    <div className={style.datosFactura}>
                        <p>nombre:</p><p>{facturaPendiente[1].nombre_usuario}</p>
                        <p>telefono:</p><p>{facturaPendiente[1].telefono_usuario}</p>
                        <p>correo:</p><p>{facturaPendiente[1].correo_usuario}</p>
                        <p>entrenamiento:</p><p>{facturaPendiente[1].id_entrenamiento}</p>
                        <p>&nbsp;</p><p>&nbsp;</p>
                        <p>valor:</p><p>{facturaPendiente[1].costo_pagar} cop</p>
                    </div>
                    <br/>
                    Desea continuar la transacción pendiente?
                    <p>&nbsp;</p>
                    <button className='pagar' style={{height:"4.5vh", width:"100%"}} onClick={e => PagarPendiente(e, facturaPendiente)}>
                        Pagar pendiente
                    </button>
                    <p>&nbsp;</p>
                    <button className='pagar' style={{height:"4.5vh", width:"100%"}} onClick={() => setFacturaPendiente(null)}>
                        Continuar actual
                    </button>
                </div>
            }
            <button className='pagar' style={{height:"4.5vh", width:"100%", opacity: facturaPendiente? 0.5:1}} onClick={facturaPendiente?() => {}:handleClick}>
                {(total > 0 && !props.sesionesPrueba)?"PAGAR":"ENTRAR"}
            </button>
            <p>&nbsp;</p>
        </>
    );
}

export default Checkout;
